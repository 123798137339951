/* ************* Sidebar ***************** */

.dashboard-sidebar {
  background: url("/Assets/Images/Dashboard-images/index.png")
    #b3b3b3 no-repeat 100% 100%;
  background-size: 65% auto;
  height: 100%;
  padding-bottom: 150px;
}
.top-sidebar {
  height: 130px;
  background: url("/Assets/Images/Icons/user.png") #d6d6d6
    no-repeat 50% 50%;
  background-size: 50px 50px;
  box-shadow: inset 0 -20px 15px -15px rgba(26, 26, 26, 0.5);
}
.overload {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(39, 39, 39, 0.521);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}
.top-sidebar:hover .overload {
  opacity: 1;
  visibility: visible;
}
.overload > input {
  display: none;
}
.overload > label {
  color: #fff;
  width: 150px;
  text-align: center;
}
.user-info-sidebar {
  padding: 15px 20px;
  border-bottom: 1px solid #f1f1f1;
  box-shadow: 0 5px 2px -1px rgba(82, 82, 82, 0.3);
}

.username-sidebar {
  text-align: center;
  padding: 20px 25px;
  border-bottom: 1px solid #f1f1f1;
  box-shadow: 0 5px 2px -1px rgba(82, 82, 82, 0.3);
}
.sidebar-bottom {
  padding: 20px 25px;
  border-bottom: 1px solid #f1f1f1;
}
.sidebar-bottom .level {
  display: inline-block;
  padding: 7px;
  border-radius: 30%;
  margin: 5px 0;
  color: #fff;
}

/* ************* ProfleService ***************** */

.services {
  margin-top: 100px;
}
.services .servicItemTop {
  height: 70%;
  display: flex;
  justify-content: center;
}
.services .servicItemTop > img {
  height: 70px;
  width: 70px;
}

.services .servicItemBottom {
  height: 40px;
  background: #c79c69;
  color: #fff;
  display: flex;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  cursor: pointer;
}
.services .servicItemBottom:hover {
  background: #c79d69a4;
}

.info-holder {
  position: absolute;
  transition: 0.3s;
  z-index: 1;
  top: 0;
}

.info-holder > .item {
  height: 65px;
  width: 300px;
  padding: 15px;
  border-bottom: 1px solid black;
  cursor: pointer;
  background: #ccc;
  display: flex;
  align-items: center;
  justify-content: end;
  transition: 0.3s;
}

.info-holder > .item:hover {
  background: rgb(148, 147, 147);
}
.info-holder > .item > label {
  height: 40px;
  width: 40px;
  margin: 0 0 0 10px;
  background: url("/Assets/Images/Icons/chevron-circle-right.png")
    no-repeat 0 0/100% 100%;
}
.info-holder > .item.show > label {
  background: url("/Assets/Images/Icons/chevron-circle-left.png")
    no-repeat 0 0/100% 100%;
}
.info-holder > .item.show > span {
  color: #bb401e;
}

.followerbody {
  width: 400px;
  background: #ccc;
  position: absolute;
  padding: 20px 15px;
  transition: 0.3s;
  z-index: 5;
}
.followerbody.hide {
  visibility: hidden;
  opacity: 0;
  left: 0;
}
.followerbody.show {
  visibility: visible;
  opacity: 1;
  left: 300px;
}

.followingbody {
  width: 400px;
  background: #ccc;
  position: absolute;
  padding: 20px 15px;
  transition: 0.3s;
  z-index: 5;
  top: 0;
}
.followingbody.hide {
  visibility: hidden;
  opacity: 0;
  left: 0;
}
.followingbody.show {
  visibility: visible;
  opacity: 1;
  left: 300px;
}

.identitybody {
  width: 400px;
  background: #ccc;
  position: absolute;
  padding: 20px 15px;
  transition: 0.3s;
  z-index: 5;
  top: 0;
}

.identitybody .useremail {
  width: 100%;
  padding: 7px;
  background: rgb(233, 232, 232);
  border-radius: 5px;
}
.identitybody.hide {
  visibility: hidden;
  opacity: 0;
  left: 0;
}
.identitybody.show {
  visibility: visible;
  opacity: 1;
  left: 300px;
}
.count-holder {
  display: flex;
  justify-content: end;
}
.count-holder > span {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
}
.count-holder > span.count {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: #3f51b5;
  color: #fff;
}
.followeritem {
  height: 70px;
  width: 100%;
  margin: 6px 0;
  border-radius: 5px;
  background: #fff;
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 13px;
}
.followeritem > span {
  margin: 0 8px;
}
.followeritem > img {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  padding: 5px;
  background: #ccc;
}
.followeritem:hover > img {
  animation: imgUpDown 1s alternate infinite;
}

@keyframes imgUpDown {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}

/* ******************** dashboard setting ********************* */
.dashboard-setting {
  background: rgba(90, 90, 90, 0.7);
  height: 100%;
  width: 100%;
  z-index: 8;
  position: absolute;
  font-size: 15px;
  transition: 0.3s;
}
.dashboard-setting.hide {
  opacity: 0;
  visibility: hidden;
}
.dashboard-setting.show {
  opacity: 1;
  visibility: visible;
}
.dashboard-setting .setting-holder {
  width: 300px;
  padding: 20px;
  background: #e6e6e6;
  height: auto;
}
.dashboard-setting .setting-holder > p {
  padding: 5px;
  background: #e6e6e6;
}
.dashboard-setting .setting-holder > a {
  padding: 8px;
  width: 100%;
  display: block;
  margin: 8px 0;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-decoration: none;
  transition: 0.3s;
}
.dashboard-setting .setting-holder > .edit {
  background: #3f51b5;
}
.dashboard-setting .setting-holder > .signout {
  background: #fff;
  color: #333;
}
.dashboard-setting .setting-holder > .signout:hover {
  background: rgba(240, 240, 240, 0.747);
}
.dashboard-setting .setting-holder > .changepass {
  border: 1.2px solid #ccc;
  color: #333;
}
.dashboard-setting .setting-holder > .changepass:hover {
  background: rgba(240, 240, 240, 0.747);
}
.dashboard-setting .setting-holder > .news {
  border-top: 1.2px solid #ccc;
  padding-top: 5px;
  margin-top: 15px;
}
