/* reset css ***********************************/

@font-face {
  font-family: yekan;
  src: url("./assets/fonts/Yekan.ttf");
}

body {
  margin: 0;
  font-family: yekan;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

a {
  color: #333;

  text-decoration: none !important;
}
.rtl {
  direction: rtl;
}
